* {
  box-sizing: border-box;
}

:root {
  --amplify-primary-color: #3f51b5;
  --amplify-primary-tint: #2e3f9b;
  --amplify-text-xs: 16px;
  --amplify-text-xxs: 16px;
  --amplify-text-sm: 16px;
}
